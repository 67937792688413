import React from 'react';
import { MainModal, Title } from 'src/shared/ui';
import { TModalType } from '../../types';
import { Button, Form, Input, Typography } from 'antd';
import { TFormValues } from './types';
import styles from './styles.module.less';
import { getNotification } from 'src/utils';
import { clientBalanceUp, clientBalanceWithdrawal, TClientChangeBalanceResponse } from 'src/shared/api/queries/billing';
import { useClient } from 'src/pages/profiles/clientsProfile/model';

interface IProps {
  readonly onClose: () => void;
  readonly onFinish: (value: TClientChangeBalanceResponse) => void;
  readonly type: TModalType;
}

export const BalanceModal: React.FC<IProps> = ({ onClose, onFinish, type }) => {
  const [form] = Form.useForm<TFormValues>();
  const client = useClient();

  const onSubmit = async (values: TFormValues) => {
    console.log('onSubmit:', values, client);
    
    if (!client?.id) {
      return;
    }

    try {
      onFinish(
        type === 'up'
          ? await clientBalanceUp({
              userId: client?.id,
              amount: values.amount * 100,
            })
          : await clientBalanceWithdrawal({
              userId: client?.id,
              amount: values.amount * 100,
            }),
      );
      onClose()
    } catch (error) {
      getNotification({
        error,
        altMessage: 'Ошибка изменения баланса',
      });
    }
  };

  return (
    <MainModal isOpen closeHandler={onClose}>
      <div className={styles.container}>
        <Title>{type === 'up' ? 'Пополнить баланс' : 'Списать с баланса'}</Title>
        {type === 'up' && (
          <Typography.Text className={styles.description}>
            Пополняйте баланс только после получения денег.
          </Typography.Text>
        )}
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          onFinish={onSubmit}
          autoComplete="off">
          <Form.Item
            label={type === 'up' ? 'Сумма пополнения, в ₽' : 'Сумма списания, в ₽'}
            name="amount"
            rules={[
              {
                type: 'number',
                max: 999999999,
                min: 0,
                message: 'Число больше нуля',
                transform(value) {
                  return Number(value);
                },
              },
            ]}>
            <Input placeholder="0" />
          </Form.Item>

          <div className={styles.buttons}>
            <Form.Item>
              <Button htmlType="submit" className={styles.button} type="primary">
                Готово
              </Button>
            </Form.Item>

            <Button onClick={onClose} className={styles.button}>
              Отмена
            </Button>
          </div>
        </Form>
      </div>
    </MainModal>
  );
};
