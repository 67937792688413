import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Spin, Select } from 'antd';
import { DetailedOrder } from 'src/pages/orders/order/types';
import styles from './ConcatOrdersModal.module.less';
import { MainModal } from 'src/shared/ui';
import { useConcatOrdersModal } from './hooks/useConcatOrdersModal';
import { divider } from '@uiw/react-md-editor';

interface IProps {
  readonly onClose: () => void;
  readonly order: DetailedOrder;
  readonly id?: string;
}

export const ConcatOrdersModal: React.FC<IProps> = ({ onClose, order, id }) => {
  const { options, concatOrders, loading } = useConcatOrdersModal({ order, orderId: id, onClose });
  const [selectedId, setSelectedId] = useState<string>();

  return (
    <MainModal
      isOpen
      closeHandler={onClose}
      title='Объединить заказы'
      subTitle='После объединения этот заказ будет следовать по статусам за выбранным заказом'
      isCloseInside
    >
      {!options ? (
        <div className={styles.loader}>
          <Spin size='large' />
        </div>
      ) : (
        <div className={styles.container}>
          <Form layout='vertical'>
            <Form.Item label='Заказ, за которым следовать'>
              <Select className={styles.select} onChange={setSelectedId}>
                {options.map(({ id, code }) => (
                  <Select.Option className={styles.option} value={id} key={id}>
                    {code}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
          <div className={styles.buttons}>
            <Button
              htmlType='submit'
              className={styles.button}
              type='primary'
              onClick={() => concatOrders(Number(selectedId))}
              disabled={loading}
              loading={loading}
            >
              Готово
            </Button>
            <Button onClick={onClose} className={styles.button} disabled={loading}>
              Отмена
            </Button>
          </div>
        </div>
      )}
    </MainModal>
  );
};
