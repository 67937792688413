import { DetailedOrder } from 'pages/orders/order/types';
import { useEffect, useMemo, useState } from 'react';
import { ordersApi, usersApi } from 'src/shared/api';
import { TUserOrderEntity } from 'src/shared/api/queries/usersApi';
import { getNotification } from 'src/utils';

type TReturnValues = {
  readonly options?: TUserOrderEntity[];
  readonly loading: boolean;
  readonly concatOrders: (value: number) => Promise<void>;
};

interface IProps {
  readonly order: DetailedOrder;
  readonly orderId?: string;
  readonly onClose: () => void;
}

export const useConcatOrdersModal = ({ order, orderId, onClose }: IProps): TReturnValues => {
  const [options, setOptions] = useState<TUserOrderEntity[]>();
  const [loading, setLoading] = useState(false);

  const fetchOrders = async () => {
    if (!orderId) {
      return;
    }

    try {
      setLoading(true);
      const response = await usersApi.ordersSearch({
        clientId: order?.client?.id,
        orderId: Number(orderId),
      });
      setOptions(response.items);
    } catch (error) {
      getNotification({ error, altMessage: 'Ошибка получения заказов' });
    } finally {
      setLoading(false);
    }
  };

  const concatOrders = async (id: number) => {
    if (!orderId) {
      return;
    }

    try {
      await ordersApi.deliveryCombine({
        id: orderId,
        data: {
          orderId: id,
          clientId: order.client.id,
        },
      });
      getNotification({ altMessage: 'Заказы объединены', type: 'success' });
      onClose()
    } catch (error) {
      getNotification({ error, altMessage: 'Ошибка объединения заказов' });
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [orderId]);

  const values = useMemo(
    () => ({
      options,
      concatOrders,
      loading,
    }),
    [options, loading],
  );

  return values;
};
