import { notification } from 'antd';
import { AxiosError } from 'axios';

export const getNotification = ({
  error,
  altMessage,
  type = 'error'
}: {
  error?: any;
  altMessage?: string;
  type?: 'error' | 'success';
}) => {
  const e = error as AxiosError<{ message: string }>
  const obj = {
    message: e?.response?.data?.message || altMessage || '',
  }
  switch (type) {
    case 'success': notification.success(obj);
      break;
    default: notification.error(obj);
  }
};
