export const Money = {
  addCommas: ({
    summa,
    format,
    isKopek,
  }: {
    summa: string | number;
    format?: boolean;
    isKopek?: boolean;
  }) => {
    summa = isKopek ? String(Number(summa) / 100) : summa;
    summa += '';
    const x = String(summa).split('.');
    let x1 = x[0];
    // @ts-ignore
    const x2 = x.length > 1 ? `.${x[1]}` : ''; // @ts-ignore
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, `$1 $2`);
    }
    return format ? Money.currencyFormat(x1 + x2) : x1 + x2;
  },
  currencyFormat: (summa: string) => `${summa} ₽`,
};
